import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import NameGenerator from './namegenerator';
import AppCreator from './appcreator';
import Wonley from './wonley';
import Robotgf from './robotgf';
import Research from './research';
import AppList from './applist';
import Schedule from './schedule';
import Pricing from './pricing';
import Login from './login';
import Docs from './docs';
import ScheduleGPT from './schedulegpt';
import Creator from './creator';
import reportWebVitals from './reportWebVitals';
import { Auth0Provider } from "@auth0/auth0-react";
import { HelmetProvider } from 'react-helmet-async';

const root = ReactDOM.createRoot(document.getElementById('root'));
// const url = window.location.pathname;


let componentToRender;

const hostname = window.location.hostname;
const path = window.location.pathname;
// if (hostname === 'openapps.ai') {
//   // if (path === '') {
//   //   componentToRender = <AppList />;
//   // } else 
//   if (path === '/') {
//     componentToRender = <AppList />;
//   } else if (path === '/namegenerator/') {
//     componentToRender = <NameGenerator />;
//   } else if (path === '/research/') {
//     componentToRender = <Research />;
//   } else if (path === '/appcreator/') {
//     componentToRender = <AppCreator />;
//   } else if (path === '/wonley/') {
//     componentToRender = <Wonley />;
//   } else if (path === '/appcreator') {
//     componentToRender = <AppCreator />;
//   } else if (path === '/chat/') {
//     componentToRender = <App />;
//   } else if (path === '/pricing/') {
//     componentToRender = <Pricing />;
//   } else if (path === '/docs/') {
//     componentToRender = <Docs />;
//   } else if (path === '/creator/') {
//     componentToRender = <Creator />;
//   } else if (path === '/login/') {
//     componentToRender = <Login />;
//   } else if (path === '/schedule/') {
//     componentToRender = <Schedule />;
//   } else if (path === '/applist/') {
//     componentToRender = <AppList />;
//   }
// } 
if (hostname === 'chatwithgreats.com' || hostname === 'clonedteam.com' || hostname === 'localhost') {
  // if (path === '') {
  //   componentToRender = <Schedule />;
  // } else 
  if (path === '/') {
    componentToRender = <Wonley />;
  } else if (path === '/namegenerator/') {
    componentToRender = <NameGenerator />;
  } else if (path === '/research/') {
    componentToRender = <Research />;
  } else if (path === '/appcreator/') {
    componentToRender = <AppCreator />;
  } else if (path === '/appcreator') {
    componentToRender = <AppCreator />;
  } else if (path === '/wonley/') {
    componentToRender = <Wonley />;
  } else if (path === '/robotgf/') {
    componentToRender = <Robotgf />;
  } else if (path === '/chat/') {
    componentToRender = <App />;
  } else if (path === '/pricing/') {
    componentToRender = <Pricing />;
  } else if (path === '/docs/') {
    componentToRender = <Docs />;
  } else if (path === '/creator/') {
    componentToRender = <Creator />;
  } else if (path === '/login/') {
    componentToRender = <Login />;
  } else if (path === '/schedule/') {
    componentToRender = <Schedule />;
  } else if (path === '/applist/') {
    componentToRender = <AppList />;
  }
}
if (hostname === 'getrobotgirlfriend.com' || hostname === 'bothookup.com') {
  // if (path === '') {
  //   componentToRender = <Schedule />;
  // } else 
  if (path === '/') {
    componentToRender = <Robotgf />;
  } else if (path === '/namegenerator/') {
    componentToRender = <NameGenerator />;
  } else if (path === '/research/') {
    componentToRender = <Research />;
  } else if (path === '/appcreator/') {
    componentToRender = <AppCreator />;
  } else if (path === '/appcreator') {
    componentToRender = <AppCreator />;
  } else if (path === '/wonley/') {
    componentToRender = <Wonley />;
  } else if (path === '/robotgf/') {
    componentToRender = <Robotgf />;
  } else if (path === '/chat/') {
    componentToRender = <App />;
  } else if (path === '/pricing/') {
    componentToRender = <Pricing />;
  } else if (path === '/docs/') {
    componentToRender = <Docs />;
  } else if (path === '/creator/') {
    componentToRender = <Creator />;
  } else if (path === '/login/') {
    componentToRender = <Login />;
  } else if (path === '/schedule/') {
    componentToRender = <Schedule />;
  } else if (path === '/applist/') {
    componentToRender = <AppList />;
  }
}




root.render(
  <React.StrictMode>
    <Auth0Provider
      domain="dev-xs40ki83h14zh2p6.us.auth0.com"
      clientId="N15I30abSNarxJT66b73lMZ2ymx7TmuY"
      redirectUri={window.location.origin}
    >
      <HelmetProvider>
        {componentToRender}
      </HelmetProvider>
    </Auth0Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
