import React, { useState, useEffect, useRef } from 'react';
import { loadStripe } from '@stripe/stripe-js';





const Modal = ({ onClose, onClose2 }) => {
  const [stripe, setStripe] = useState(null);
  const [card, setCard] = useState(null);
  const cardElement = useRef(null);

  const [error, setError] = useState(null);
  const [selectedOption, setSelectedOption] = useState(1);
function handleSelectChange(event) {
  const selectedOption = parseInt(event.target.value);
  setSelectedOption(selectedOption);
  setIsFree(selectedOption === 0);
}

useEffect(() => {
  //const stripePromise = loadStripe("pk_test_51MVEmGKewlRyf6JgpzliBeSXBvyxJwJbK4ng0QkiGeqIt6BG8L4gZiYmPcfXSewpOCJ82ygWBq8VX4TanAdTrIHE00kmCZn2m3");
  const stripePromise = loadStripe("pk_live_51MVEmGKewlRyf6JgxR58mffMC2rZGbqqler9mE11g8Q2jpbpmmVDdPZGUZMRLNXNvbu4WIwGWrPElrTQ57hcXjKT00jlsAcfxz");
  stripePromise.then((stripe) => {
    // console.log('Stripe object:', stripe);
    setStripe(stripe);
    const elements = stripe.elements();
    const newCard = elements.create('card', {
      appearance: {
        theme: "stripe"
      }
    });
    // console.log('Card element created:', newCard);
    setCard(newCard);
  });
}, []);

useEffect(() => {
  if (card) {
    // console.log('Mounting card element...');
    card.mount(cardElement.current);
    return () => {
      // console.log('Unmounting card element...');
      card.unmount();
    };
  }
}, [card]);

  const nonloggedinuser_id = localStorage.getItem('nonloggedinuser_id');
  const [isFree, setIsFree] = useState(true); // set initial value to true
  const [showSuccess, setShowSuccess] = useState(false);
  const handlepayerSubmit = async (event) => {
    event.preventDefault();
    document.getElementById("paybutton").innerText = "Processing...";
    document.getElementById("paybutton").classList.add("green");

   

    
    let token;

   // alert('1' + isFree);

    if (!isFree){

              if (!card) {
                console.error('Error: Card element not initialized.');
                return;
              }
            
              const { error, token } = await stripe.createToken(card);
            
              if (error) {
                console.error('Error creating token:', error);
                setError(error.message);
              } else {
                // console.log('Success!', token);
            
                if (token) {
                  // Send the token to your server
                  const nonloggedinuser_id = localStorage.getItem('nonloggedinuser_id');
                  const amountOfStaff = document.getElementById("amountofstaff").value;
                  const payeremail = document.getElementById("payeremail").value;
                  // console.log(`Submitting tip with amount: ${amountOfStaff} and email: ${payeremail}`);
            
                  fetch('https://openapps.ai/API_DB/SavePayerSuccess', {
                    method: 'POST',
                    body: JSON.stringify({ token: token.id, nonloggedinuser_id: nonloggedinuser_id, user_id: null, paid_status: true, amount_of_staff: amountOfStaff, payer_email: payeremail }),
                    headers: { 'Content-Type': 'application/json' },
                  })
                    .then(response => response.json())
                    .then(data => {
                      
                      // Update the success message here
            
                      setTimeout(function () {
            
                        // Update the user's email and password in the database
                        const updateUserEndpoint = `https://openapps.ai/API_DB/update_user/${nonloggedinuser_id}`;
            
                        fetch(updateUserEndpoint, {
                          method: 'POST',
                          body: JSON.stringify({ email: payeremail }),
                          headers: { 'Content-Type': 'application/json' },
                        })
                          .then(response => response.json())
                          .then(data => {
                            setShowSuccess(true);
                            let password = data.password;
                            handleLogin(password, payeremail);

                            // console.log("User updated successfully");
                          })
                          
                          .catch(err => {
                            // console.log("Error updating user: ", err);
                          });
            
                      }, 3500); // 2000 milliseconds = 2 seconds
                    });
            
                  // Charge the customer
                  fetch('https://openapps.ai/API_DB/createcharge', {
                    method: 'POST',
                    body: JSON.stringify({ token: token.id, amount_of_staff: amountOfStaff }),
                    headers: { 'Content-Type': 'application/json' },
                  })
                    .then(response => response.json())
                    .then(data => {
                      // console.log(data);
                    });
                }
              }
            }


    if (isFree) {
        // Update the user's email and password in the database
  const payeremail = document.getElementById("payeremail").value;
  const updateUserEndpoint = `https://openapps.ai/API_DB/update_user/${nonloggedinuser_id}`;

 // alert('2' + nonloggedinuser_id + payeremail + updateUserEndpoint);

  fetch(updateUserEndpoint, {
    method: 'POST',
    body: JSON.stringify({ email: payeremail }),
    headers: { 'Content-Type': 'application/json' },
  })
    .then(response => response.json())
    .then(data => {
    //  alert(data);
      // console.log("User updated successfully");
      let password = data.password;
      handleLogin(password, payeremail);
      document.getElementById("paybutton").classList.remove("green");
   //   alert('Success! Account created. Check your email for login details');

       

    })
    
    
    .catch(err => {
      // console.log("Error updating user: ", err);
    });

   // alert('3');

  // Submit the payment to the database
  fetch('https://openapps.ai/API_DB/SavePayerSuccess', {
    method: 'POST',
    body: JSON.stringify({ token: isFree ? null : token.id, nonloggedinuser_id: nonloggedinuser_id, user_id: null, paid_status: !isFree, amount_of_staff: selectedOption, payer_email: payeremail }),
    headers: { 'Content-Type': 'application/json' },
  })
    .then(response => response.json())
    .then(data => {
      setShowSuccess(true);
      // Update the success message here
    });
    }
  };



  const handleLogin = (password, payeremail) => {
   // alert('logging in!')
  
    const data = {
      email: payeremail,
      password: password
    };
  
    fetch('https://openapps.ai/API_DB/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error('Login failed');
      }
    })
    .then(data => {
      // Login successful, set the cookie with userId and redirect to dashboard
      // const userId = data.userId;
      // const userEmail = data.user_email;

    //  alert(data.user_email);
    //  alert(data.userId);

      localStorage.setItem('user_email', data.user_email); // Add this line to set the userEmail in local storage
      document.cookie = `userId=${data.userId}; path=/`;

      //alert('success!');
      // Check if the URL contains "pricing"
      if (window.location.href.indexOf("schedulegpt.ai") > -1) {
        window.parent.document.getElementById("submit").click();}
        else {
          console.log("Submit button not found");
        }
      if (window.location.href.indexOf("pricing") > -1) {
        window.location.href = "../"; // Redirect to the parent directory
      } else {
        // Close the modal here
      }
    })
    
    .catch(error => {
      console.error('Error:', error);
    });
  }

  

  return (
    <div className="modal-overlay" id="modal-overlay">
      <div className="modal-content">
        {showSuccess ? (
          <div><span className='modalsuccess'>Success!</span><br></br><span className='modelbody'>You are now logged in. Loging details have been sent. You can now click close below:<br></br> </span><button onClick={onClose} class="nothanks"><b>Close</b></button></div>
        ) : (
          <>
            <h1 className='modaltitle'>Overlord Mode!</h1>
            Command an army of AI, ready to help with any task, guide you to any goal... or just relax & chat with!<br></br>
            {stripe && (

<form onSubmit={handlepayerSubmit}>
<label>
  <select
    className='signupinput'
    id='amountofstaff'
    defaultValue='1'
    onChange={handleSelectChange}
  >
    {/* <option value='0'>FREE - 30 tasks /mo</option> */}
    <option value='1'>Unlimited Bots</option>
  </select>
</label>
<label>
  <input
    className='signupinput'
    id='payeremail'
    type='email'
    placeholder='Enter your email'
  />
</label>
<div id="card-element" ref={cardElement}></div>
<label className="costlabel">${selectedOption}/day. Less than a coffee ☕</label>
<button className='paybutton' id='paybutton' type='submit'>
  Upgrade!
</button>
</form>
            )}
            <div class="nothanks" onClick={onClose2}>No thanks, not just yet</div>
          </>
        )}
      </div>
    </div>
  );
  
};

export default Modal;
